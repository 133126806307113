import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import * as Scroll from "react-scroll";
import Card5 from "../assets/Services/services-hangar.jpg";
import Tick from "../assets/icon-tick.svg";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import Started from "../components/Layout/started";
import Efficiency from "../assets/Training/icon-centrik-training-efficiency-driven-delivery.svg";
import Empowered from "../assets/Training/icon-centrik-training-empowered-through-technology.svg";
import Value from "../assets/Training/icon-centrik-training-value-from-day-one.svg";

const { scroller } = Scroll;

const Background = styled.div`
  width: 100vw;
  background-image: linear-gradient(to top, #f8fcff, var(--white));
`;

const Background2 = styled.div`
  width: 100vw;
  background-image: linear-gradient(to top, #f8fcff, var(--white) 33%);
`;

const QualityWrapper = styled.div`
  padding: 100px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  color: #242d41;
  & h2 {
    grid-column: 1/4;
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    padding: 40px 0;
    & h2 {
      grid-column: auto;
      font-size: 28px;
    }
  }
`;

const ConvoWrapper = styled.div`
  grid-row: 1/4;
  grid-column: 4/5;
  @media screen and (max-width: 576px) {
    grid-row: auto;
    grid-column: auto;
  }
  @media screen and (max-width: 820px) {
    margin-top: 90px;
  }
`;

const QualityText = styled.p`
  grid-row: 2/3;
  grid-column: 1/4;
  font-size: 18px;
  line-height: 1.56;
  color: rgba(36, 45, 65, 0.9);
  padding-right: 140px;
  padding-bottom: 40px;
  @media screen and (max-width: 991px) {
    padding-right: 70px;
  }
  @media screen and (max-width: 576px) {
    grid-row: auto;
    grid-column: auto;
    padding-right: 0;
    font-size: 16px;
  }
`;

const SectorDiv = styled.div`
  padding-right: 24px;
  @media screen and (max-width: 576px) {
    text-align: center;
  }
  & img {
    height: 120px;
    width: 120px;
  }
`;

const SectorTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 24px;
`;

const SectorText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.8);
`;

const ConvoHeader = styled.h5`
  font-weight: 600;
  margin-top: 24px;
`;

const LineBreak = styled.div`
  height: 1px;
  background-color: #eeeff3;
  margin-top: 28px;
  margin-bottom: 28px;
`;

const Contact = styled.div`
  margin: 28px 0 0;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 6px 20px 0 rgba(36, 45, 65, 0.1);
  background-color: #ffffff;
`;

const Email = styled.h6`
  line-height: 1.5;
  color: #126fd6;
`;

const TickDiv = styled.div`
  display: flex;
  margin-bottom: 12px;
`;
const TickImg = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 16px;
`;

const TickText = styled.span`
  color: rgba(36, 45, 65, 0.7);
  @media screen and (max-width: 451px) {
    text-align: left;
  }
`;

const WorldWideDiv = styled.div`
  margin: 150px 0;
  display: flex;
  color: rgba(36, 45, 65, 0.9);
  @media screen and (max-width: 991px) {
    margin: 60px 0;
    flex-direction: column;
    & h2 {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 576px) {
    margin: 40px 0;
    flex-direction: column;
    & h2 {
      font-size: 22px;
    }
  }
`;

const WorldWideText = styled.p`
  font-size: 18px;
  line-height: 1.56;
  padding-bottom: 20px;
  @media screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const EngineerImage = styled.img`
  max-height: 400px;
  border-radius: 15px;
  @media screen and (max-width: 991px) {
    max-height: fit-content;
  }
  @media screen and (max-width: 820px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  flex: 8;
  @media screen and (max-width: 576px) {
    flex: 1;
  }
`;

const TextWrapper = styled.div`
  flex: 10;
  @media screen and (max-width: 991px) {
    flex: 1;
    margin-top: 60px;
  }
  @media screen and (max-width: 576px) {
    flex: 1;
    margin-top: 40px;
  }
`;

const TabSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #eeeff3;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const Tab = styled.div`
  text-align: center;
  border-bottom: ${({ selected }) => (selected ? "1px solid #1e5bb5" : "none")};
  color: ${({ selected }) => (selected ? "#1e5bb5" : "#363f41")};
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03px;
  cursor: pointer;
  padding: 0px 20px 30px 20px;
  transition: 0.4s all;
  &:hover {
    color: #1e5bb5;
  }
  @media screen and (max-width: 991px) {
    font-size: 16px;
    padding: 15px 10px;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px;
    padding: 15px 10px;
  }
`;

const SelectedTab = styled.div`
  margin-top: 80px;
  color: rgba(36, 45, 65, 0.9);
  padding-bottom: 170px;
  @media screen and (max-width: 991px) {
    margin-top: 60px;
    padding-bottom: 120px;
    & h2 {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 576px) {
    margin-top: 40px;
    padding-bottom: 80px;
    & h2 {
      font-size: 22px;
    }
  }
`;

const SelectedTabText = styled.p`
  font-size: 18px;
  line-height: 1.56;
  margin-bottom: ${({ reduceMargin }) => (reduceMargin ? "28px" : "80px")};
  max-width: 1100px;
  @media screen and (max-width: 576px) {
    font-size: 16px;
    text-align: middle;
  }
`;

const TabCardParagraphWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const SelectedTabTextWrapper = styled.div`
  flex: 1;
`;

const SelectedTabImageWrapper = styled.div`
  flex: 1;
  text-align: center;
  margin-bottom: 30px;
  & img {
    max-width: 490px;
    max-height: 340px;
  }
  @media screen and (max-width: 576px) {
    font-size: 16px;
    text-align: middle;
  }
`;

const TabCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
  & img {
    height: 120px;
    width: 120px;
    margin-bottom: 36px;
  }
  & h5 {
    color: #1e5bb5;
    min-height: ${({ height }) => (height ? `${height}px` : "auto")};
  }
  & p {
    font-size: 16px;
    font-weight: light;
  }
  & ${TickImg} {
    height: 25px;
    width: 25px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 991px) {
    & h2 {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    text-align: center;
    & h2 {
      font-size: 22px;
    }
  }
`;

const Training = (props) => {
  const sectors = [
    {
      img: Empowered,
      title: "Empowered Through Technology",
      text: "Centrik Training equips teams to fully utilize advanced digital platforms from day one—ensuring accurate reporting, real-time oversight, and confident execution using the latest aviation technology​.",
    },
    {
      img: Efficiency,
      title: "Efficiency-Driven Delivery",
      text: "Our expert-led programs eliminate the need for extensive internal training resources by providing modular, aviation-focused instruction that reduces onboarding time, streamlines operations, and maintains continuity across roles​.",
    },
    {
      img: Value,
      title: "Value From Day One",
      text: "Designed to accelerate time-to-value, our training programs reduce the learning curve, preserve institutional knowledge, and ensure compliance—delivering immediate ROI and long-term operational confidence​.",
    },
  ];
  const cards = [
    {
      key: "SCP",
      title: "Safety Continuity Program",
      cardTitle: "Safety Continuity Program",
      cardText:
        "The Safety Continuity Program is built for long-term operational resilience. This training stream focuses on preserving institutional knowledge, supporting new admins and managers, and maintaining regulatory compliance even during periods of personnel change. It delivers in-depth module training, guidance on best practices, and real-time coaching to foster confident leadership within the system. By helping organizations quickly onboard new team members while ensuring process continuity, the program plays a critical role in sustaining safety culture and performance. Post-training support and modular delivery ensure the program fits seamlessly into ongoing operations.",
    },
    {
      key: "EUT",
      title: "End User Training",
      cardTitle: "End User Training",
      cardText:
        "The End User Training package is focused on equipping daily users of Centrik with the essential knowledge and confidence to navigate and operate the platform effectively. Ideal for both new and existing employees, this program includes essential module overviews, live instruction, and access to trainers for post-session questions. With a strong emphasis on time efficiency, sessions are concise yet impactful—minimizing operational disruption while maximizing uptake. The training is tailored to specific job functions, helping users understand their role within the Centrik ecosystem and contribute immediately to safe and efficient operations.",
    },
    {
      key: "FSC",
      title: "Full System Configuration",
      cardTitle: "Full System Configuration",
      cardText:
        "The Complete System Training package offers a comprehensive onboarding experience tailored for new Centrik implementations. Designed for administrators and key users, it delivers in-depth overviews of all Centrik modules, ensuring a thorough understanding of configuration and setup. Delivered by aviation professionals, this program incorporates real-time training, best practices, and structured modular sessions to ensure teams are empowered to fully leverage Centrik's capabilities from day one. Access to expert trainers, post-training resources, and optional on-site delivery support complete this immersive setup experience, making it ideal for organizations seeking rapid time-to-value and operational readiness.",
    },
  ];

  const [selectedTab, setSelectedTab] = useState(cards[0]);
  const [passedTab, setPassedTab] = useState(null);

  const scrollToTabs = () => {
    const { hash } = window.location;
    if (!hash) {
      return;
    }
    const selectedTabIndex =
      cards.findIndex(
        (card) => card.key.toLowerCase() === hash.slice(1).toLowerCase()
      ) || 0;
    setSelectedTab(cards[selectedTabIndex]);
    scroller.scrollTo("tabSection", {
      duration: 500,
      smooth: true,
      offset: -100,
    });
  };

  useEffect(() => {
    if (passedTab) {
      if (passedTab === "SCP") {
        window.location.hash = `scp`;
      } else if (passedTab === "EUT") {
        window.location.hash = `eut`;
      } else if (passedTab === "FSC") {
        window.location.hash = `fsc`;
      }
      scrollToTabs();
      setPassedTab(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedTab]);

  useEffect(() => {
    if (props.location?.state?.from) {
      setPassedTab(props.location?.state?.from);
    }
  }, [props.location?.state?.from]);

  useEffect(() => {
    if (window.location && window.location.hash) scrollToTabs();
    window.addEventListener("hashchange", scrollToTabs);
    return () => window.removeEventListener("hashchange", scrollToTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Seo />
      <PageHeader
        header="TrustFlight Training"
        subHeader="Empowering aviation teams to elevate safety, compliance, and performance through expert-led training."
        mediumHeader
        longTag
      />

      <Background>
        <Container>
          <QualityWrapper>
            <h2>Expert-Led Solutions for Lasting Operational Excellence</h2>
            <QualityText>
              Our team brings deep expertise from across the aviation industry,
              combining extensive operational leadership experience in flight
              operations, engineering, maintenance, safety, and quality
              management. Our training solutions are designed to help aviation
              organizations achieve safer, more efficient, and compliant
              operations, empowering them to scale effectively—from initial
              startup through to managing large, complex fleets.
            </QualityText>

            {sectors.map((sector) => (
              <SectorDiv key={sector.title}>
                <img src={sector.img} alt={"tech-logo"}></img>
                <SectorTitle>{sector.title}</SectorTitle>
                <SectorText>{sector.text}</SectorText>
              </SectorDiv>
            ))}
            <ConvoWrapper>
              <ConvoHeader>Start the conversation</ConvoHeader>
              <LineBreak />
              <Contact>
                <p>
                  If you require more information or need any specialist advice
                  then get in touch with us
                </p>
                <Email>
                  <a href={"mailto:training@trustflight.com"}>
                    training@trustflight.com
                  </a>
                </Email>
              </Contact>
            </ConvoWrapper>
          </QualityWrapper>
        </Container>
      </Background>
      <Container>
        <WorldWideDiv>
          <ImageWrapper>
            <EngineerImage src={Card5} />
          </ImageWrapper>
          <TextWrapper>
            <h2>Global Training Expertise</h2>
            <WorldWideText>
              We have the experience and skills to efficiently support all
              aircraft types and operators globally.
            </WorldWideText>
            <div>
              <TickDiv>
                <TickImg src={Tick} />
                <TickText>
                  Flexible delivery formats tailored to your operational
                  tempo—live virtual, in-person, or blended sessions
                </TickText>
              </TickDiv>
              <TickDiv>
                <TickImg src={Tick} />
                <TickText>
                  Over 60 subject matter experts spanning Flight and Ground
                  Operations, Airworthiness, Safety, and Compliance to pull
                  insights from
                </TickText>
              </TickDiv>
              <TickDiv>
                <TickImg src={Tick} />
                <TickText>
                  Instructional design specialists skilled in modern learning
                  techniques, including blended, gamified, and virtual learning
                  environments
                </TickText>
              </TickDiv>
              <TickDiv>
                <TickImg src={Tick} />
                <TickText>
                  Long-term support through recorded sessions and continued
                  access to trainers for seamless onboarding and knowledge
                  transfer
                </TickText>
              </TickDiv>
              <TickDiv>
                <TickImg src={Tick} />
                <TickText>
                  Operational training expertise across Business Aviation,
                  Commercial Aviation, MROs, and regulators, supporting both
                  fixed-wing, rotary-wing, and advanced air mobility operators
                </TickText>
              </TickDiv>
            </div>
          </TextWrapper>
        </WorldWideDiv>
      </Container>
      <Background2>
        <Container>
          <TabSection name="tabSection">
            {cards.map((card) => (
              <Tab
                key={card.key}
                onClick={() =>
                  (window.location.hash = `#${card.key.toLowerCase()}`)
                }
                selected={card.key === selectedTab?.key}
              >
                {card.title}
              </Tab>
            ))}
          </TabSection>
          <SelectedTab>
            <React.Fragment key={selectedTab?.key}>
              <h2>{selectedTab?.cardTitle}</h2>
              <TabCardParagraphWrapper>
                <SelectedTabTextWrapper>
                  <SelectedTabText
                    reduceMargin={!!selectedTab?.secondParagraph}
                  >
                    {selectedTab?.cardText}
                  </SelectedTabText>
                  {selectedTab?.secondParagraph && (
                    <SelectedTabText>
                      {selectedTab?.secondParagraph}
                    </SelectedTabText>
                  )}
                </SelectedTabTextWrapper>
                {selectedTab?.cardImg && (
                  <SelectedTabImageWrapper>
                    <img src={selectedTab?.cardImg} alt="card" />
                  </SelectedTabImageWrapper>
                )}
              </TabCardParagraphWrapper>

              <Started
                title="Let's start the conversation"
                text="Talk to our training team to find out how you can benefit from our expertise."
                hubSpotFormId="41a8067f-c272-4dd9-a386-00946023ec8d"
              />
            </React.Fragment>
          </SelectedTab>
        </Container>
      </Background2>
    </Layout>
  );
};
export default Training;
